import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal12 from "../images/palestrantes/nestor.jpg" // Tell webpack this JS file uses this image
import Footer from "../components/footer"
import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image
import pal24 from "../images/palestrantes/jose-dronda.png" // Tell webpack this JS file uses this image
import pal25 from "../images/palestrantes/jose-gonzalez.png" // Tell webpack this JS file uses this image
import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image
import logo5 from "../images/logos/westpoint.jpg" // Tell webpack this JS file uses this image
import logo6 from "../images/logos/atm.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
            <div className="texto inter-bold">
             <span className="opacity"> Gestão e saúde  </span> <br></br>
             de Engordas<br></br> de borregos
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal25} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                     Dr. José Maria Gonzalez Sainz
                      </div>
                    
                    <div className="titulo inter-light">
                     Consultor de engordas de borregos
                      </div>
                      </div>
                    
                  </div>

                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal24} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Dr. José Maria Bello Dronda
                      </div>
                    
                    <div className="titulo inter-light">
                     Consultor nutricionista de engordas de borregos
                      </div>
                      </div>
                    
                  </div>
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                      26 e 27 de Janeiro de 2023
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                     Espanhol
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      500€+IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/brochura-gestao-saude-engorda-borregos.pdf" target="_blank" >
                <button className="btn-brochure inter-bold">Brochura</button> </a>
                </div>
                <div className="form">
                <a href="https://forms.gle/s4pRfJ9eR5WpLwL19" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={fotomasterclass} alt="" />
        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Descrição
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
              <div className="desc inter-light">
              Com mais de 15 de experiência em consultoria de engordas de borregos, José Dronda e José Sainz, vão dar uma formação presencial em Portugal com duração de 2 dias,  com o objetivo de abordar temas relacionados com a gestão, decisões económicas, compreensão de protocolos de entrada, gestão da "saúde", alimentação e necrópsias. Esta formação está direcionada para técnicos, gestores e proprietários de engordas.                </div>
                <div className="logos">
                  
               

                  </div>
            </div>
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
      
      </div>
      <div className="wrapper-program">
      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            + Dia 1 - 26/01/2023 - Teórica e presencial
                            </Accordion.Header>

                            <Accordion.Body>
                               
                            <div class="modulo">
                                <p><span className="date">09:00 - 09:15</span> | <span class="mod">Módulo 1</span> - <span class="txt">Apresentação da formação</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">09:30 - 10:30</span> | <span className="mod">Módulo 2</span> - <span class="txt">Economia da engorda de borregos</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">10:30 - 11:30</span> | <span className="mod">Módulo 3</span> - <span class="txt">Instalações, bem-estar e o impacto nas saúde - parte I</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:30 - 12:00</span> | <span className="mod">Coffee-break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">12:00 - 13:00</span> | <span className="mod">Módulo 4</span> - <span class="txt">Instalações, bem-estar e o impacto nas saúde - parte II</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 15:00</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">15:00 - 16:00</span> | <span className="mod">Módulo 5</span> - <span class="txt">Complexo Respiratório Ovino I (Epidemiologia, Implicações económicas)</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:00 - 17:00</span> | <span className="mod">Módulo 6</span> - <span class="txt">Complexo Respiratório Ovino II (Etiologia, Tratamento, prevenção)</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">17:00 - 17:30</span> | <span className="mod">Coffee-Break</span> </p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">17:30 - 18:30</span> | <span className="mod">Módulo 8</span> - <span class="txt">Parasitoses</span></p>
                                </div>
          
                    

                                
                                </Accordion.Body>

                                </Accordion.Item>
                        

                                <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            +   Dia 2 - 27/01/2023 - Teórica e presencial
                            </Accordion.Header>

                            <Accordion.Body>
                            <div class="modulo">
                                <p><span className="date">09:00 - 10:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Alimentação</span></p>
                                </div> 
  
                                <div class="modulo">
                                  <p><span className="date">10:00 - 11:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Doenças digestivas</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:00 - 11:30</span> | <span className="mod">Coffee-Break</span> </p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:30 - 12:30</span> | <span className="mod">Módulo 3</span> - <span class="txt">Doenças metabólicas e carências</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">12:30 - 15:00</span> | <span className="mod">Almoço</span> </p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">15:00 - 18:00</span> | <span className="mod">Módulo 4</span> - <span class="txt">Necropsias em exploração</span></p>
                                </div>
          
                                </Accordion.Body>
                                </Accordion.Item>

                    </Accordion>
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/brochura-gestao-saude-engorda-borregos.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="https://forms.gle/s4pRfJ9eR5WpLwL19" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 
                </a>

                
          </div>
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal25} alt="" />

              </div>
              <div className="nome">
             Dr. José Maria Gonzalez Sainz
              </div>
              <div className="titulo">
              Consultor de engordas de borregos
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Veterinário, consultor e especialista em engordas de pequenos ruminantes 

</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
Diplomado Europeu - European College of Small Ruminant Health Management
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Professor Associado da Universidad de Zaragoza
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
Coordenador del Programa Nacional de reducción del uso de antibióticos en ovino y caprino de carne e leche
</p>
                  

                 
                  </div>
              </div>
            </div>
        </div>
        </div>

        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal24} alt="" />

              </div>
              <div className="nome">
              Dr. José Maria Bello Dronda
              </div>
              <div className="titulo">
              Consultor nutricionista de engordas de borregos
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> Consultor, nutricionista e especialista em engorda de pequenos ruminantes
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 

                  Chefe de Produto de pequenos ruminantes na empresa NANTA S.A</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span>

                  Diplomado Europeu - European College of Small Ruminant Health Management </p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span>

Desde 1987 a 2005 - Serviço Técnico de ruminantes - NANTA </p>
<p><span className="icone"><CheckIcon></CheckIcon></span>

Desde 2005 a 2010 - Chefe de produto de pequenos ruminantes - NANTA S.A en Espanha e Portugal </p>
           
                 
                  </div>
              </div>
            </div>
        </div>
        </div>
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
